/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {useHistory, Link} from 'react-router-dom'
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";
import moment from "moment";

type Props = {
  className: string
}

const requestShareholders = (accessToken: any) => {
  return axios.post(`${BACKEND_URL}/investor/list`, {
    access_token: accessToken
  }).then(data => data.data)
}

const TableInvestor: React.FC<Props> = ({className}) => {
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)
  const [investors, setInvestors] = useState([])
  const history = useHistory()

  const [searchKeyword, setSearchKeyword] = useState('')
  const [displayInvestors, setDisplayInvestor] = useState([])
  const currentUser: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  useEffect(() => {
    // lọc theo từ khóa
    let displayInvestors: any = []
    if (searchKeyword) {
      investors.forEach((investor) => {
        // moi customer la mot object
        let isPush = false;
        Object.entries(investor).forEach(([key, value]) => {
          if (value && String(value).normalize().toLowerCase().includes(searchKeyword.normalize().toLowerCase()) && !isPush) {
            displayInvestors.push(investor)
            isPush = true;
          }
        });
      })
    } else {
      displayInvestors = [...investors];
    }

    setDisplayInvestor(displayInvestors)

  }, [searchKeyword, investors])


  useEffect(() => {
    // lay danh sach co dong
    requestShareholders(accessToken).then(resData => {
      if (resData.error_code === 1) {
        setInvestors(resData.data.investors)
      }
    })
    return () => {}
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Danh sách nhà đầu tư</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Thêm nhà đầu tư mới'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary d-none'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
            onClick={(e) => {
              e.preventDefault()
              history.push('/create-shareholder')
            }}
          >
            <KTSVG path='media/icons/duotone/Communication/Add-user.svg' className='svg-icon-3' />
            Thêm nhà đầu tư mới
          </a>

          <input
            style={{ width: '80%' }}
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            type="text" className="form-control me-2 mt-2 mb-2" placeholder="Từ khóa"
          />

        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-150px'>Tên nhà đầu tư</th>
                <th className='min-w-150px'>Tên đăng nhập</th>
                <th className='min-w-140px'>Địa chỉ ví</th>
                <th className='min-w-450px'>Link ref</th>
                <th className='min-w-140px'>Email</th>
                <th className='min-w-140px'>Mã bí mật</th>
                <th className='min-w-100px text-end'>Thao tác</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {displayInvestors.map((investor: any) => {
                if (investor.username != 'quandm') {
                  return (
                      <tr key={investor.id}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                          <span className='text-dark fw-bolder text-hover-primary fs-6'>
                            {investor.name ?? ''}
                          </span>
                            </div>
                          </div>
                        </td>
                        <td>
                      <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                        {investor.username ?? ''}
                      </span>
                        </td>
                        <td className='text-end'>
                          <span className='text-dark fw-bolder text-hover-primary fs-6'>
                            {investor.wallet_address ?? ''}
                          </span>
                        </td>
                        <td className='text-start'>
                          <span className='text-dark fw-bolder text-hover-primary fs-6'>
                            {'https://robotanan.net/auth/registration?partner=' + investor.username}
                          </span>
                        </td>
                        <td>
                      <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                        {investor.email ?? ''}
                      </span>
                        </td>
                        <td>
                      <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                        {investor.scan_code ?? ''}
                      </span>
                        </td>
                        <td>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <Link to={`/view-investor/${investor.id}`}>
                              <div
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <KTSVG
                                    path='/media/icons/duotone/General/Settings-1.svg'
                                    className='svg-icon-3'
                                />
                              </div>
                            </Link>
                            {currentUser.user.username == 'ketoan' &&
                                <Link to={`/edit-investor/${investor.id}`}>
                                  <div
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  >
                                    <KTSVG
                                        path='/media/icons/duotone/Communication/Write.svg'
                                        className='svg-icon-3'
                                    />
                                  </div>
                                </Link>
                            }
                          </div>
                        </td>
                      </tr>
                  )
                }
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TableInvestor}
